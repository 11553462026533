/* eslint-disable no-process-env */
import { merge } from 'lodash';
import { Configuration } from 'rollbar';
import i18n from '@root/i18n.config';
import urlConfig from '@root/url.config';

export enum SERVER_ENV_ENUM {
  local = 'local',
  dev = 'dev',
  uat = 'uat',
  prd = 'prd',
  docker = 'docker',
}

export enum FEATURES_SECTIONS {
  EVENT_BACK_TO_HOME,
  PDG_HARDCODE,
}

const SERVER_ENV: SERVER_ENV_ENUM = SERVER_ENV_ENUM[process.env.NEXT_PUBLIC_SERVER_ENV] || SERVER_ENV_ENUM.local;

interface Environment {
  environment: SERVER_ENV_ENUM,
  urlBase: string,
  urlApi: {
    clientSide: string,
    serverSide: string
  },
  connector: {
    url: string,
    credentials: boolean,
  },
  urlBackendAssets: string,
  rollbar: Configuration & { serverAccessToken: string; },
  mailToSupport: string,
  devTools: boolean,
  licenseCAS: {
    shopUrl: string
  },
  customHandlings: {
    euroCategoriesIds: number,
  },
  pdg: {
    id: string,
    slug: string,
  },
  helpUrl:{
    faq: string,
    registration: {
      FR: string,
      EN: string,
      DE: string,
    },
    password: {
      FR: string,
      EN: string,
      DE: string,
    },
    transfer: {
      FR: string,
      EN: string,
      DE: string,
    }
  },
  documentsPath: {
    cga: {
      FR: string,
      EN: string,
      DE: string,
    },
    cgaSummary: string,
    privacyPolicy: string,
    termOfUse: string,
  },
  googleAnalytics: {
    active: boolean,
    id: string,
  },
  maps: {
    defaultZoom: number,
    zIndex: number,
  },
  featuresFlags: {
    [FEATURES_SECTIONS.PDG_HARDCODE]:
      { enabled: boolean }
  },
  datatrans?: { production: boolean },
  lists: {
    defaultPage: number,
    defaultPageSize: number,
    largePageSize: number,
    currentEventsSize: number,
    searchMinimumCharactersText: number,
    searchMinimumBirthYear: number,
    numberOfResultsCategories: number,
    numberOfResultsScratch: number,
    lazyLoadAutoRefreshInterval: number,
  },
  recaptcha: {
    defaultLocale: string;
    key: string;
    useRecaptchaNet?: boolean;
    useEnterprise?: boolean;
  },
  urls: {
    googleMaps: string;
    home: string,
    base: string,
    aboutMSO: string,
    organiser: string,
    contact: string,
  }
}

const environment = {
  docker: {
    // docker mode
    environment: SERVER_ENV_ENUM.docker,
    connector: {
      url: 'https://localhost:8083/connectors/msoprod-connector/status',
      credentials: true,
    },
    urlBackendAssets: 'https://www-mso-dev.appti.ch/',
    rollbar: { enabled: false },
    mailToSupport: 'dev+testmail@apptitude.ch',
  },
  local: {
    // dev mode
    environment: SERVER_ENV_ENUM.local,
    connector: {
      url: 'https://connect-mso-dev.appti.ch/connectors/msoprod-connector/status',
      credentials: true,
    },
    urlBackendAssets: 'https://www-mso-dev.appti.ch/',
    rollbar: { enabled: false },
    mailToSupport: 'dev+testmail@apptitude.ch',
  },
  dev: {
    //  Run on Apptitude SWARM
    environment: SERVER_ENV_ENUM.dev,
    connector: {
      url: 'http://connect-service:8083/connectors/msoprod-connector/status',
      credentials: false,
    },
    urlBackendAssets: 'https://www-mso-dev.appti.ch/',
    urls: { home: 'https://www-mso-dev.appti.ch/' },
    rollbar: { enabled: true },
    mailToSupport: 'dev+testmail@apptitude.ch',
  },
  uat: {
    //  Run on Silicom AZURE
    environment: SERVER_ENV_ENUM.uat,
    connector: {
      url: 'http://connect-service.mso-uat.svc.cluster.local:8083/connectors/msopreprod-newmysql-connector/status',
      credentials: false,
    },
    urlBackendAssets: 'https://test.mso-chrono.ch/',
    urls: { home: 'https://test.mso-chrono.ch/' },
    pdg: {
      id: '1545',
      slug: '1545-patrouille-des-glaciers',
    },
  },
  prd: {
    //  Run on Silicom AZURE
    environment: SERVER_ENV_ENUM.prd,
    connector: {
      url: 'http://connect-service.mso-prd.svc.cluster.local:8083/connectors/msoprod-connector-flex/status',
      credentials: false,
    },
    urls: { home: 'https://www.mso-chrono.ch/' },
    urlBackendAssets: 'https://www.mso-chrono.ch/',
    datatrans: { production: true },
    devTools: false,
    pdg: {
      id: '1545',
      slug: '1545-patrouille-des-glaciers',
    },
    googleAnalytics: {
      active: true,
      id: 'G-NH50QWYBK9',
    },
  },
}[SERVER_ENV];

/* eslint-disable no-console */
console.log('================== ENV CONFIG ==================');
console.log(` SERVER_ENV : ${SERVER_ENV}`);
console.log('environment :', environment);
console.log('================================================');
/* eslint-enable no-console */

const config = {
  SERVER_ENV,
  devTools: true,
  helpUrl: {
    faq: {
      fr: 'https://help.mso.swiss/hc/fr',
      en: 'https://help.mso.swiss/hc/en-us',
      de: 'https://help.mso.swiss/hc/de',
    },
    registration: {
      fr: 'https://help.mso.swiss/hc/fr/articles/4414319627409-Comment-puis-je-modifier-un-service-dans-mon-inscription-taille-de-T-Shirt-num%C3%A9ro-d-urgence-etc-',
      en: 'https://help.mso.swiss/hc/en-us/articles/4414319627409-How-can-I-change-a-service-in-my-registration-T-shirt-size-emergency-number-etc-',
      de: 'https://help.mso.swiss/hc/de/articles/4414319627409-Wie-kann-ich-einen-Service-in-meiner-Anmeldung-%C3%A4ndern-T-Shirt-Gr%C3%B6%C3%9Fe-Notrufnummer-usw-',
    },
    password: {
      fr: 'https://help.mso.swiss/hc/fr/articles/4414322638353-Comment-r%C3%A9cup%C3%A9rer-mon-mot-de-passe-',
      en: 'https://help.mso.swiss/hc/en-us/articles/4414322638353-How-can-I-retrieve-my-password-',
      de: 'https://help.mso.swiss/hc/de/articles/4414322638353-Wie-kann-ich-mein-Passwort-wiederherstellen-',
    },
    transfer: {
      fr: 'https://help.mso.swiss/hc/fr/articles/4414323365521-Comment-transf%C3%A9rer-mon-dossard-%C3%A0-un-autre-athl%C3%A8te-',
      en: 'https://help.mso.swiss/hc/en-us/articles/4414323365521-How-do-I-transfer-my-bib-to-another-athlete-',
      de: 'https://help.mso.swiss/hc/de/articles/4414323365521-Wie-%C3%BCbertrage-ich-meine-Startnummer-an-einen-anderen-Athleten-',
    },
  },
  documentsPath: {
    cga: {
      fr: '/documents/cga-fr.pdf',
      en: '/documents/cga-fr.pdf',
      de: '/documents/cga-fr.pdf',
    },
    cgaSummary: {
      fr: '/documents/cga-summary-fr.pdf',
      en: '/documents/cga-summary-fr.pdf',
      de: '/documents/cga-summary-fr.pdf',
    },
    privacyPolicy: '/documents/privacy-policy.pdf',
    termOfUse: '/documents/term-of-use.pdf',
  },
  datatrans: { production: false },
  licenseCAS: { shopUrl: 'https://www.mso-chrono.ch/shop/cas/index.php' },
  customHandlings: { euroCategoriesIds: 50000000 },
  pdg: {
    id: '1454',
    slug: '1454-patrouille-des-glaciers',
  },
  googleAnalytics: {
    active: false,
    id: '',
  },
  lists: {
    defaultPage: 0,
    defaultPageSize: 100,
    largePageSize: 999,
    currentEventsSize: 7,
    searchMinimumCharactersText: 2,
    searchMinimumBirthYear: 1900,
    numberOfResultsCategories: 3,
    numberOfResultsScratch: 10,
    lazyLoadAutoRefreshInterval: 6000,
  },
  maps: {
    defaultZoom: 17,
    zIndex: 0,
  },
  rollbar: {
    enabled: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_KEY,
    payload: {
      environment: SERVER_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.NEXT_BUILD_ID,
          guess_uncaught_frames: true,
        },
      },
    },
    serverAccessToken: process.env.NEXT_ROLLBAR_SERVER_KEY,
  },

  featuresFlags: { [FEATURES_SECTIONS.PDG_HARDCODE]: { enabled: true } },
  mailToSupport: 'support@mso-chrono.ch',
  recaptcha: {
    key: '6Lfx2aMdAAAAANZYhBUQVXRydKjqdEW3GNNMpw_L',
    defaultLocale: i18n.defaultLocale,
  },
  urls: {
    googleMaps: 'https://www.google.com/maps/place/MSO+S%C3%A0rl/@47.3513905,7.3353045,17z/data=!3m1!4b1!4m5!3m4!1s0x4791e7b585fc96b3:0x5bc9d7d54ce32ce3!8m2!3d47.3513886!4d7.3374953',
    base: 'https://www.mso-chrono.ch',
    aboutMSO: 'https://business.mso.swiss',
    organiser: 'https://business.mso.swiss/services/',
    contact: 'https://business.mso.swiss/contact/',
  },
};
const url = urlConfig[SERVER_ENV];
export default merge({}, config, environment, url) as Environment;
